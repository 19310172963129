.reset-screen {
  padding: 5em 4em;
  width: 100%;
  @media screen and (min-width: 1440px) {
    width: 100%;
    min-width: 700px;
  }
  @media screen and (max-width: 1440px) {
    width: 100%;
    min-width: 700px;
  }
  @media screen and (max-width: 1025px) {
    width: 100%;
    min-width: 700px;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    min-width: 300px;
  }
  @media screen and (max-width: 550px) {
    padding: 1.5em;
    height: 100%;
    min-width: 200px;
  }
  background-color: @white-color;
  height: 100%;
  h1 {
    font-weight: 600;
    font-size: 40px;
  }
  p {
    font-size: 17px;
  }
  svg.google-logo {
    margin-right: 60px;
    @media screen and (max-width: 1024px) {
      margin-right: 10px;
    }
  }

  button.ant-btn.d-flex.align-center.fill-width.font-18.mt-25.flex-wrap {
    @media screen and (max-width: 769px) {
      flex-direction: column;
      width: 100%;
    }
  }

  .reset-form {
    margin-top: 40px;
    label {
      color: @black-color;
    }
    span {
      font-size: 16px;
    }
    span.ant-input-affix-wrapper {
      padding: 10px 20px;
    }
    button.ant-btn.ant-btn-primary.fill-width {
      margin-top: 30px;
      height: 45px;
      border-radius: 8px;
    }
  }
}

.reset {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 24.01%, rgba(0, 0, 0, 0.39) 100%),
    url(~assets/images/loginImage.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  width: 94%;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
  img.logo {
    width: 7em;
    height: auto;
    margin-left: 25px;
    margin-top: 25px;
  }
  &-text {
    padding: 100px;
    color: @white-color;
    width: 60%;
    @media screen and (max-width: 1440px) {
      width: 80%;
      padding: 80px;
    }
    @media screen and (max-width: 1025px) {
      width: 90%;
      padding: 50px;
    }
    h1 {
      color: @white-color;
      font-size: xxx-large;
      font-weight: bold;
    }
  }
  .create-account-button {
    color: @black-color;
    font-size: 16px;
    font-weight: bold;
    margin-top: 25px;
    background: rgba(255, 255, 255, 0.8);
    &:hover {
      border-color: rgba(255, 255, 255, 0.8);
    }
  }
}

.mobile-login-header {
  background: @white-color;
  padding: 15px;
}
